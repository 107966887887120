import { mapActions } from 'vuex'

export default {
    name: 'TeacherMailConfirm',
    data: () => ({
        loading: true,
        message: '',
        error: false,
    }),
    created() {
        this.confirm()
    },
    methods: {
        ...mapActions('teacher', ['ActionMailConfirm']),
        confirm() {

            var token = this.$route.query.token;

            const payload = {
                token: token
            }

            this.ActionMailConfirm(payload)
                .then((r) => {
                    this.message = r.message
                })
                .catch((e) => {
                    this.message = e.message
                    this.error = true
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
}